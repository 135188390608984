import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse } from '~/api/types/Api'

export default class VehicleService extends ApiServiceBase {
  async create(postData: object): Promise<ApiResponse<object>> {
    return await this.call('/v1/vehicle-changes', postData)
  }
  async list(query?: object | null): Promise<ApiResponse<object>> {
    return await this.call('/v1/vehicle-changes?include=documents,documentTypes', query ?? {})
  }
  async delete(id: string, postData: object): Promise<ApiResponse<object>> {
    return await this.call(`/v1/vehicle-changes/${id}`, postData)
  }
  async submit(id: string, postData: object): Promise<ApiResponse<object>> {
    const submittedVChangeResponse = await this.call(`/v1/vehicle-changes/${id}/submit`, postData)

    if (submittedVChangeResponse.data?.status === 'pending') {
      const body = new FormData()
      body.append('subject', 'Vehicle change Submitted')
      body.append(
        'comment',
        `A new vehicle change has been submitted pending approval. We will respond to this shortly to approve or reject, you will be notified on this ticket and via the portal vehicle & documents section, thank you.`
      )
      body.append('custom_fields', '[]')
      body.append('tags', 'portal_j')

      const ticketResponse = await useFetch(`/api/zendesk/tickets`, {
        method: 'POST',
        body,
      })

      const ticketId = ticketResponse.data.value?.ticket?.id

      if (ticketId) {
        const vehicleChangeUrl = `https://teams.veezu.co.uk/documents/vehicle-changes/${submittedVChangeResponse.data.id}`

        const commentBody = new FormData()
        commentBody.append(
          'comment',
          `Please visit the <a href="${vehicleChangeUrl}" target="_blank">vehicle changes page</a> to approve or reject this vehicle change.<br><br>This ticket is from the new portal. Please contact IT if you do not have access.`
        )

        commentBody.append('note', `private`)

        await useFetch(`/api/zendesk/tickets/${ticketId}`, {
          method: 'PUT',
          body: commentBody,
        })
      }
    }

    return submittedVChangeResponse
  }
}
