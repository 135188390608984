import { appendHeader } from 'h3'
import { FetchError, type FetchOptions } from 'ofetch'
import { splitCookiesString } from 'set-cookie-parser'
import ApiError from '~/api/models/ApiError'
import type { User, UserData } from '~/api/types/Authentication'
import type { ApiServiceContainer } from '~/api/services/ApiServiceContainer'
import ApplicationService from '~/api/services/ApplicationService'
import AuthenticationService from '~/api/services/AuthenticationService'
import DocumentService from '~/api/services/DocumentService'
import EarningsService from '~/api/services/EarningsService'
import NewsService from '~/api/services/NewsService'
import VehicleService from '~/api/services/VehicleService'
import InvoiceService from '~/api/services/InvoiceService'
import BookingsService from '~/api/services/BookingsService'
import PaymentsService from '~/api/services/PaymentsService'
import AgreementsService from '~/api/services/AgreementService'
import PayoutService from '~/api/services/PayoutService'

const SECURE_METHODS = new Set(['post', 'delete', 'put', 'patch'])
const UNAUTHENTICATED_STATUSES = new Set([401, 419])
const UNVERIFIED_USER_STATUS = 409
const VALIDATION_ERROR_STATUS = 422

const CSRF_REQUEST_URL = '/sanctum/csrf-cookie'
const CSRF_COOKIE_NAME = 'XSRF-TOKEN'
const CSRF_HEADER_NAME = 'X-XSRF-TOKEN'

export default defineNuxtPlugin(async () => {
  const event = useRequestEvent()
  const config = useRuntimeConfig()
  const user = useUser()
  const apiConfig = config.public.api

  async function initUser(getter: () => Promise<UserData | null>) {
    try {
      const userData = await getter()
      user.value = userData?.data as User
    } catch (err) {
      if (err instanceof FetchError && err.response && UNAUTHENTICATED_STATUSES.has(err.response.status)) {
        console.warn('[API initUser] User is not authenticated')
      }
    }
  }

  function buildServerHeaders(headers: HeadersInit | undefined): HeadersInit {
    const csrfToken = useCookie(CSRF_COOKIE_NAME).value
    const clientCookies = useRequestHeaders(['cookie'])
    return {
      ...headers,
      ...(clientCookies.cookie && clientCookies),
      ...(csrfToken && { [CSRF_HEADER_NAME]: csrfToken }),
      Referer: config.public.baseUrl,
      accept: 'application/json',
    }
  }

  async function buildClientHeaders(headers: HeadersInit | undefined): Promise<HeadersInit> {
    await $fetch(CSRF_REQUEST_URL, {
      baseURL: apiConfig.baseUrl,
      credentials: 'include',
    })

    const csrfToken = useCookie(CSRF_COOKIE_NAME).value

    return {
      ...headers,
      ...(csrfToken && { [CSRF_HEADER_NAME]: csrfToken }),
      accept: 'application/json',
    }
  }

  const httpOptions: FetchOptions = {
    baseURL: apiConfig.baseUrl,
    credentials: 'credentials' in Request.prototype ? 'include' : undefined,
    headers: {
      Accept: 'application/json',
    },
    retry: false,

    async onRequest({ options }) {
      if (process.server) {
        options.headers = buildServerHeaders(options.headers)
      }

      if (process.client) {
        const method = options.method?.toLocaleLowerCase() ?? ''

        if (!SECURE_METHODS.has(method)) {
          return
        }

        options.headers = await buildClientHeaders(options.headers)
      }
    },

    onResponse({ response }) {
      if (process.server) {
        const rawCookiesHeader = response.headers.get('set-cookie')

        if (rawCookiesHeader === null) {
          return
        }

        const cookies = splitCookiesString(rawCookiesHeader)

        for (const cookie of cookies) {
          appendHeader(event, 'set-cookie', cookie)
        }
      }
    },

    async onResponseError({ response }): Promise<false | void | any> {
      if (response.status === UNVERIFIED_USER_STATUS) {
        await navigateTo(config.public.api.redirects.onVerifiedOnly)

        return
      }

      if (UNAUTHENTICATED_STATUSES.has(response.status)) {
        user.value = null

        await navigateTo(config.public.api.redirects.onAuthOnly)

        return
      }

      if (response.status === VALIDATION_ERROR_STATUS) {
        if (response._data.errors?.deleted) {
          return await navigateTo(config.public.api.redirects.onRequiresReinstate)
        }
        if (response._data.errors?.blocked) {
          return await navigateTo(config.public.api.redirects.onBlockListed)
        }
        throw new ApiError(response._data)
      }
    },
  }

  const client: any = $fetch.create(httpOptions)

  const api: ApiServiceContainer = {
    application: new ApplicationService(client),
    authentication: new AuthenticationService(client),
    news: new NewsService(client),
    documents: new DocumentService(client),
    earnings: new EarningsService(client),
    vehicle: new VehicleService(client),
    invoices: new InvoiceService(client),
    bookings: new BookingsService(client),
    payments: new PaymentsService(client),
    agreements: new AgreementsService(client),
    payouts: new PayoutService(client),
  }

  if (process.server && user.value === null) {
    await initUser(() => api.authentication.user({ query: { include: 'driver.fleet' } }))
  }

  return { provide: { api } }
})
